.team-section {
  background-color: #000000;
  padding: 6rem 2rem;
  color: white;
  width: 100%;
}

.team-container {
  max-width: 1200px;
  margin: 0 auto;
}

.team-header {
  text-align: center;
  margin-bottom: 4rem;
}

.team-title {
  font-size: 3.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: white;
}

.team-subtitle {
  font-size: 1.25rem;
  color: #9ca3af;
  max-width: 48rem;
  margin: 0 auto;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  max-width: 64rem;
  margin: 0 auto;
}

.team-member {
  text-align: center;
}

.member-image-container {
  width: 16rem;
  height: 16rem;
  margin: 0 auto 1.5rem;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.member-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.member-name {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.member-role {
  color: #9ca3af;
  margin-bottom: 0.75rem;
  font-size: 1.1rem;
}

.member-bio {
  color: #d1d5db;
  margin-bottom: 1rem;
  line-height: 1.6;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.member-social {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.social-link {
  color: #9ca3af;
  transition: color 0.3s ease;
}

.social-link:hover {
  color: white;
}

.social-icon {
  width: 1.5rem;
  height: 1.5rem;
}

@media (max-width: 768px) {
  .team-section {
    padding: 4rem 1.5rem;
  }

  .team-title {
    font-size: 2.5rem;
  }

  .team-grid {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .member-image-container {
    width: 14rem;
    height: 14rem;
  }
} 