.about-section {
  background-color: #000000;
  padding: 6rem 2rem;
  color: white;
  width: 100%;
}

.about-container {
  max-width: 1200px;
  margin: 0 auto;
}

.about-content {
  text-align: center;
}

.about-title {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 2rem;
  letter-spacing: -0.5px;
  color: white;
}

.about-description {
  font-size: 1.25rem;
  line-height: 1.6;
  margin-bottom: 10px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.9;
  color: white;
}

.about-features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
}

.feature {
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.feature:hover {
  transform: translateY(-5px);
}

.feature h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: white;
}

.feature p {
  font-size: 1rem;
  line-height: 1.5;
  opacity: 0.8;
  color: white;
}

@media (max-width: 768px) {
  .about-features {
    grid-template-columns: 1fr;
  }
  
  .about-title {
    font-size: 2.5rem;
  }
  
  .about-description {
    font-size: 1.1rem;
  }
} 