.services-section {
  background-color: #000000;
  padding: 6rem 2rem;
  color: white;
  width: 100%;
}

.services-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.services-title {
  font-size: 3.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
  letter-spacing: -0.5px;
}

.services-description {
  font-size: 1.25rem;
  line-height: 1.6;
  margin-bottom: 4rem;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.9;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.service-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease;
}

.service-item:hover {
  transform: translateY(-5px);
}

.service-icon-container {
  width: 140px;
  height: 140px;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border-radius: 50%;
  padding: 1.5rem;
}

.service-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: none;
}

.service-title {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.4;
  max-width: 200px;
  margin: 0 auto;
}

@media (max-width: 1024px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .services-section {
    padding: 4rem 1.5rem;
  }

  .services-title {
    font-size: 2.5rem;
  }

  .services-description {
    font-size: 1.1rem;
    margin-bottom: 3rem;
  }

  .services-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .service-icon-container {
    width: 120px;
    height: 120px;
  }
} 