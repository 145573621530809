.partners-section {
  background-color: #000000;
  padding: 4rem 2rem;
  width: 100%;
}

.partners-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6rem;
}

.partner-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.partner-logo {
  height: auto;
  max-width: 100%;
  object-fit: contain;
}

.harvard-logo {
  width: 350px;
}

.mit-logo {
  width: 400px;
}

@media (max-width: 768px) {
  .partners-container {
    flex-direction: column;
    gap: 3rem;
  }

  .harvard-logo {
    width: 280px;
  }

  .mit-logo {
    width: 320px;
  }
} 