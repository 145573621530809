nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  width: 100%;
}

.space-x-2 > :not(:last-child) {
  margin-right: 8px;
}

.space-x-8 > :not(:last-child) {
  margin-right: 32px;
}

.text-white {
  color: white;
}

.hover\:text-gray-300:hover {
  color: #D1D5DB;
}

.transition-colors {
  transition: color 0.3s ease;
}

.bg-white {
  background-color: white;
}

.text-black {
  color: black;
}

.px-4 {
  padding-left: 16px;
  padding-right: 16px;
}

.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.rounded-md {
  border-radius: 4px;
}

.hover\:bg-gray-200:hover {
  background-color: #E5E7EB;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.logo-container {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.logo {
  height: 40px;
  width: auto;
  transition: opacity 0.3s ease;
}

.logo:hover {
  opacity: 0.8;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-left: auto;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-family: inherit;
}

.nav-link:hover {
  color: #4169E1;
}

.contact-button {
  background-color: #4169E1;
  color: white;
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.3s ease;
  border: none;
  cursor: pointer;
  font-family: inherit;
}

.contact-button:hover {
  background-color: #3457c9;
}

.md\:hidden {
  display: none;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }
  
  .container {
    padding: 0 1rem;
  }
  
  .logo {
    height: 32px;
  }
} 