.home {
  min-height: 100vh;
  position: relative;
  padding-top: 80px; /* Height of the nav */
}

.hero-section {
  position: relative;
  height: calc(100vh - 80px); /* Subtract nav height */
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.hero-content {
  text-align: center;
  color: white;
  max-width: 800px;
  padding: 0 2rem;
  z-index: 1;
  font-family: Arial, sans-serif;
}

.hero-title {
  font-size: 4rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  font-family: Arial, sans-serif;
  letter-spacing: -0.5px;
}

.hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  opacity: 0.9;
  font-family: Arial, sans-serif;
  font-weight: 300;
}

.learn-more-button {
  background-color: white;
  color: black;
  border: none;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 500;
  font-family: Arial, sans-serif;
  letter-spacing: 0.5px;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.learn-more-button:hover {
  transform: translateY(-2px);
} 