.contact-section {
  background-color: #000000;
  padding: 6rem 2rem;
  color: white;
  width: 100%;
}

.contact-container {
  max-width: 1200px;
  margin: 0 auto;
}

.contact-header {
  text-align: center;
  margin-bottom: 4rem;
}

.contact-title {
  font-size: 3.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: white;
}

.contact-subtitle {
  font-size: 1.25rem;
  color: #9ca3af;
  max-width: 48rem;
  margin: 0 auto;
}

.contact-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  max-width: 64rem;
  margin: 0 auto;
}

.contact-info {
  padding-right: 2rem;
}

.contact-info-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.contact-info-items {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.contact-info-item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.contact-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: white;
  flex-shrink: 0;
}

.contact-info-label {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.contact-info-text {
  color: #9ca3af;
  font-size: 1rem;
}

.contact-form-container {
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 8px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-label {
  font-size: 0.875rem;
  font-weight: 500;
}

.form-input,
.form-textarea {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  padding: 0.75rem;
  color: white;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-input:focus,
.form-textarea:focus {
  outline: none;
  border-color: #4169E1;
}

.form-textarea {
  min-height: 120px;
  resize: vertical;
}

.submit-button {
  background-color: #4169E1;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #3457c9;
}

.submit-button:disabled {
  background-color: #6b7280;
  cursor: not-allowed;
}

.submit-status {
  margin-top: 1rem;
  padding: 0.75rem;
  border-radius: 4px;
  text-align: center;
  font-size: 0.875rem;
}

.submit-status.success {
  background-color: rgba(34, 197, 94, 0.1);
  color: #4ade80;
  border: 1px solid rgba(34, 197, 94, 0.2);
}

.submit-status.error {
  background-color: rgba(239, 68, 68, 0.1);
  color: #f87171;
  border: 1px solid rgba(239, 68, 68, 0.2);
}

@media (max-width: 768px) {
  .contact-section {
    padding: 4rem 1.5rem;
  }

  .contact-title {
    font-size: 2.5rem;
  }

  .contact-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .contact-info {
    padding-right: 0;
  }
} 